<template>
  <div class="profile-data-wrapper" v-if="profile" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_roster &&
        userPermissions.profile_roster.length > 0 &&
        userPermissions.profile_roster.includes('View')
      "
    >
      <div class="row grid-2 profile-detail">
        <div class="col">
          <div class="db-cards h-100">
            <div class="profile-header">
              <div class="row align-items-center">
                <div class="col flex-grow-1">
                  <div class="profile-info">
                    <span>
                      <template v-if="profile.profile_professional_detail.PhotoPath">
                        <img
                          :src="`${uploadBaseUrl}${profile.profile_professional_detail.PhotoPath}`"
                          alt="pro"
                        />
                      </template>
                      <template v-else>
                        <img src="@/assets/images/db-pro.jpg" alt="avatar" />
                      </template>
                    </span>
                    <div class="profile-name">{{ profile.ProfileName }}</div>
                    <div class="profile-work">{{ profile.Username }}</div>
                  </div>
                </div>
                <div class="profile-date col">
                  <span>Created On:{{ $filters.dateFormat(profile.created_at, "DD/MM/YYYY") }}</span><br/><br/>
                  <span>Source:{{ (profile.IsImported) ? 'Data Import' : 'Manual' }}</span><br/><br/>
                  <span>Last Updated: {{ $filters.dateFormat(profile.updated_at, "DD/MM/YYYY") }}</span>
                  <!-- <span>Created On Upwork: {{ $filters.dateFormat(profile.createdOnUpworks, "DD/MM/YYYY") }}</span> -->
                </div>
              </div>
            </div>
            <div class="profile-body-content profile-datalist">
              <ul class="d-flex flex-wrap">
                <li>
                  <label>Profile Title:</label>
                  <span>{{ profile.Title }}</span>
                </li>
                <li>
                  <label>ProfileId:</label>
                  <a v-bind:href="'https://www.upwork.com/freelancers/~'+profile.ProfileId" target="_blank">Profile Link</a>
                </li>
                <li>
                  <label>Total Contracts:</label>
                  <span>{{ profile.contracts_count }}</span>
                </li>
                <li>
                  <label>Total Hours:</label>
                  <span>{{
                    profile.ProfileTotalHours % 1 === 0
                      ? Math.round(profile.ProfileTotalHours)+'/HR'
                      : profile.ProfileTotalHours +'/HR'
                  }}</span>
                </li>
                <li>
                  <label>Employment History:</label>
                  <span>
                    <template v-if="profile.employment_history_count > 0">Yes</template>
                    <template v-else>Not available</template>
                  </span>
                </li>
                <li>
                  <label>Education History:</label>
                  <span>
                    <template v-if="profile.education_history_count > 0">Yes</template>
                    <template v-else>Not available</template>
                  </span>
                </li>
                <li>
                  <label>InProgress JobCount:</label>
                  <span>
                    <template v-if="profile.InProgressJobCount> 0">{{profile.InProgressJobCount}}</template>
                    <template v-else>0</template>
                  </span>
                </li>
                <li>
                  <label>Completed JobCount:</label>
                  <span>
                    <template v-if="profile.CompleteJobCount> 0">{{profile.CompleteJobCount}}</template>
                    <template v-else>0</template>
                  </span>
                </li>
                <li>
                  <label>Total Contracts:</label>
                  <span>
                    <template v-if="profile.contracts_count> 0">{{profile.ProfileTotalJob}}</template>
                    <template v-else>0</template>
                  </span>
                </li>
                <li>
                  <label>In Progress Total Hours:</label>
                  <span>
                    <template v-if="profile.totalHoursInProgress> 0">{{profile.totalHoursInProgress+'/HR'}}</template>
                    <template v-else>0</template>
                  </span>
                </li>
                <li>
                  <label>Total Earnings:</label>
                  <span>
                    <template v-if="profile.ProfileTotalEarning">{{profile.ProfileTotalEarning}}</template>
                    <template v-else>$0</template>
                  </span>
                </li>
                <li>
                  <label>Total Jobs:</label>
                  <span>
                    <template v-if="profile.ProfileTotalJob > 0">{{profile.ProfileTotalJob}}</template>
                    <template v-else>$0</template>
                  </span>
                </li>
                <li>
                  <label>Complete Jobs Total Hours:</label>
                  <span>
                    <template v-if="profile.totalHoursCompleteJob > 0">{{profile.totalHoursCompleteJob+'/HR'}}</template>
                    <template v-else>$0</template>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="db-cards profile-graph h-100">
            <div class="profile-progress-bar">
              <div class="bar-filled" :style="`width:${progressPrecentage}%`"></div>
              <div class="bar-text">{{ progressPrecentage }}% Profile Completed</div>
            </div>
            <template
              v-if="
                !profile.profile_weekly_crawling_income ||
                profile.profile_weekly_crawling_income.reduce((partialSum, a) => partialSum + a, 0) ===
                  0
              "
            >
              <div class="no-data-wrap d-flex align-items-center justify-content-center">
                <img src="@/assets/images/no-graph.png" alt="no-graph" />
                <div class="desc">No data<br />to show</div>
              </div>
            </template>
            <template v-else>
              <div class="billing-statsWrap">
                <billing-chartjs :datasets="profile.profile_weekly_crawling_income" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- Earnings Statistics -->
      <h2 class="section-heading">Earnings Statistics</h2>
      <template
        v-if="
          !profile.earning_stats ||
          profile.earning_stats.reduce(
            (partialSum, a) => partialSum + (parseInt(a['y']) || 0),
            0
          ) === 0
        "
      >
        <div class="no-data-wrap d-flex align-items-center justify-content-center">
          <img src="@/assets/images/no-graph.png" alt="no-graph" />
          <div class="desc">No data<br />to show</div>
        </div>
      </template>
      <template v-else>
        <div class="earning-statsWrap">
          <earning-chartjs
            :datasets="profile.earning_stats"
            :labels="[]"
            v-if="profile.earning_stats"
          />
        </div>
      </template>
      <!-- In Progress (active jobs) html -->
      <div class="row align-items-center">
        <div class="col">
          <h2 class="section-heading">In Progress Jobs</h2>
        </div>
      </div>
      <div class="custom-table no-arrows activeJob-table">
        <table id="active_contracts">
          <thead>
            <tr>
              <th colspan="7"></th>
              
            </tr>
            <tr>
              <th><span class="tbl-job">Id</span></th>
              <th><span class="tbl-job">Title</span></th>
              <th><span class="tbl-job">Desc.</span></th>
              <th><span class="tbl-job">StartDate</span></th>
              <th><span class="tbl-job">TotalPrice ($)</span></th>
              <th><span class="tbl-job">PerHour</span></th>
              <th><span class="tbl-job">TotalHours</span></th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="
                profile.upwork_inprogress_jobs &&
                profile.upwork_inprogress_jobs &&
                profile.upwork_inprogress_jobs.length > 0
              "
            >
              <tr
                v-for="upwork_inprogress_job in profile.upwork_inprogress_jobs"
                :key="upwork_inprogress_job.Id"
              >
                <td>{{ upwork_inprogress_job.Id }}</td>
                <td>{{ upwork_inprogress_job.Title }}</td>
                <td>{{ upwork_inprogress_job.Description }}</td>
                <td>{{ upwork_inprogress_job.StartDate }}</td>
                <td>{{ upwork_inprogress_job.TotalPrice }}</td>
                <td>{{ upwork_inprogress_job.PerHour }}</td>
                <td>{{ upwork_inprogress_job.TotalHours }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- Complete Jobs html -->
      <div class="row align-items-center">
        <div class="col">
          <h2 class="section-heading">Complete Jobs</h2>
        </div>
      </div>
      <div class="custom-table no-arrows activeJob-table">
        <table id="active_contracts">
          <thead>
            <tr>
              <th colspan="8"></th>
              
            </tr>
            <tr>
              <th><span class="tbl-job">Id</span></th>
              <th><span class="tbl-job">Title</span></th>
              <th><span class="tbl-job">Desc.</span></th>
              <th><span class="tbl-job">StartDate</span></th>
              <th><span class="tbl-job">EndDate</span></th>
              <th><span class="tbl-job">TotalPrice ($)</span></th>
              <th><span class="tbl-job">PerHour</span></th>
              <th><span class="tbl-job">TotalHours</span></th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="
                profile.upwork_complete_jobs &&
                profile.upwork_complete_jobs &&
                profile.upwork_complete_jobs.length > 0
              "
            >
              <tr
                v-for="upwork_complete_job in profile.upwork_complete_jobs"
                :key="upwork_complete_job.Id"
              >
                <td>{{ upwork_complete_job.Id }}</td>
                <td>{{ upwork_complete_job.Title }}</td>
                <td>{{ upwork_complete_job.Description }}</td>
                <td>{{ upwork_complete_job.StartDate }}</td>
                <td>{{ upwork_complete_job.EndDate }}</td>
                <td>{{ upwork_complete_job.TotalPrice }}</td>
                <td>{{ upwork_complete_job.PerHour }}</td>
                <td>{{ upwork_complete_job.TotalHours }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- job to be closed & mapping history -->
      <div class="row grid-2">
        <div
          class="col"
          :class="{
            'equal-col': profile.mapping_history && profile.mapping_history.length > 2,
          }"
        >
          <h2 class="section-heading">Contracts To Be Closed</h2>
          <template
            v-if="profile.jobs_to_be_closed && profile.jobs_to_be_closed.length > 0"
          >
            <div class="db-cards jobs-wrap contracts-jobs-cards custom-scroll">
              <form
                v-for="job_to_close in profile.jobs_to_be_closed"
                :key="job_to_close.Id"
              >
                <div class="row grid-1">
                  <div class="col">
                    <div class="form-group title-header">
                      <div class="border-heading form-control">
                        {{ job_to_close.ContractId }} - {{ job_to_close.Title }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row grid-2">
                  <div class="col">
                    <div class="form-group title-header">
                      <div class="border-heading form-control">
                        <template v-if="job_to_close.ContractType === 'Hourly'">
                          Total Hours Worked:
                          {{
                            job_to_close.totalBillingTime
                              ? job_to_close.totalBillingTime / 60
                              : 0
                          }}
                        </template>
                        <template v-if="job_to_close.ContractType === 'Fixed Price'">
                          Total Completed Milestones:
                          {{
                            job_to_close.milestones && job_to_close.milestones.length > 0
                              ? job_to_close.milestones.length
                              : 0
                          }}
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group title-header">
                      <div
                        class="border-heading form-control"
                        v-if="job_to_close.billing"
                      >
                        Last Worked On:
                        {{ $filters.dateFormat(job_to_close.billing.Date, "YYYY-DD-MM") }}
                      </div>
                      <div class="border-heading form-control" v-else>
                        Last Worked On: N/A
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row grid-2">
                  <div class="col">
                    <div class="form-group title-header">
                      <div
                        class="border-heading form-control"
                        v-if="job_to_close.total_billing_amount_count"
                      >
                        Total Earnings: &dollar;{{
                          job_to_close.total_billing_amount_count
                        }}
                      </div>
                      <div class="border-heading form-control" v-else>
                        Total Earnings: &dollar;0
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <div class="form-group">
                      <a href="javascript:void(0);" class="primary-btn w-100">Hold</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </template>
          <template v-else>
            <div class="no-data-wrap d-flex align-items-center justify-content-center">
              <img src="@/assets/images/nodata.png" alt="no-data" />
              <div class="desc">No data<br />to show</div>
            </div>
          </template>
        </div>
        <div class="col">
          <h2 class="section-heading">Mapping History</h2>
          <div class="custom-table no-arrows">
            <table id="mappingHistory">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Resource Name</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-if="profile.mapping_history && profile.mapping_history.length > 0"
                >
                  <tr
                    v-for="mapping_history in profile.mapping_history"
                    :key="mapping_history.id"
                  >
                    <td>
                      {{ $filters.dateFormat(mapping_history.created_at, "DD/MM/YYYY") }}
                    </td>
                    <td>{{ mapping_history.b_d_team_profile.Name }}</td>
                    <td>{{ mapping_history.b_d_team_profile.Role }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Portfolios html start -->
      <div class="row align-items-center">
        <div class="col">
          <h2 class="section-heading">Portfolios</h2>
        </div>
        <div class="col flex-grow-1 d-flex justify-content-end action-links">
          <a
            class="links"
            href="javascript:void(0);"
            @click.prevent="openSideModal('addPortfolio')"
            v-if="
              userPermissions.profile_roster &&
              userPermissions.profile_roster.length > 0 &&
              userPermissions.profile_roster.includes('Add') &&
              showPortfolioBtn > 0
            "
            ><span class="lnr lnr-cross"></span> Add Portfolio</a
          >
        </div>
      </div>

      <div class="custom-table no-arrows table-colored">
        <table>
          <thead>
            <tr>
              <th>Month</th>
              <th>Contract Closed</th>
              <th>Portfolios Added</th>
              <th>Contract IDs</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="profile.portfolio">
              <tr
                v-for="(item, k) in profile.portfolio"
                :key="item.k"
                :class="{
                  'row-danger': item.contractIds.length + 0 > 0,
                  'row-primary':
                    (item.contractIds.length + 0) / 2 === 0 &&
                    (item.contractIds.length + 0) / 2 > 0,
                }"
              >
                <td>{{ k }}</td>
                <td>{{ item.contractIds.length }}</td>
                <td>{{ item.portfolio_count }}</td>
                <td>
                  <span v-for="pf in item.contractIds" :key="pf.Id">
                    <a
                      class="links"
                      href="javascript:void(0);"
                      @click.prevent="openSideModal('viewContract', '', pf.Id)"
                      >{{ pf?.ContractId }}</a
                    >&nbsp;
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!-- verification History html -->
      <h2 class="section-heading">Verification History</h2>
      <div class="custom-table no-arrows">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Person Responsible for Conduction</th>
              <th>Documents Attached</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="
                profile.verification_history && profile.verification_history.length > 0
              "
            >
              <tr
                v-for="verification_history in profile.verification_history"
                :key="verification_history.id"
              >
                <td>
                  {{ $filters.dateFormat(verification_history.created_at, "DD/MM/YYYY") }}
                </td>
                <td>
                  <template v-if="verification_history.Status === 'failure'"
                    >Failure</template
                  >
                  <template v-if="verification_history.Status === 'verified'"
                    >Verified</template
                  >
                  <template v-if="verification_history.Status === 'inProgress'"
                    >In-Progress</template
                  >
                </td>
                <td>{{ verification_history.b_d_team_profile.Name }}</td>
                <td>
                  <ul v-if="verification_history.documents.length > 0">
                    <li
                      v-for="document in verification_history.documents"
                      :key="document.id"
                    >
                      <a
                        :href="`${uploadBaseUrl + document.Documents}`"
                        target="_blank"
                        >{{ document.DocumentName }}</a
                      >
                    </li>
                  </ul>
                  <ul v-if="verification_history.documents.length == 0">
                    <li>N/A</li>
                  </ul>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="4" class="text-center">No verification history found.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind="{
          contentClass: {
            'sm-side--modal': contract.show || contract.import.show || portfolio.show,
            'contract-form': contract.manual.show,
          },
        }"
      >
        <template v-slot:header>
          <template v-if="contract.show">CREATE A NEW CONTRACT</template>
          <template v-if="contract.manual.show"
            >CREATE CONTRACT ({{ contract.profile.Name }})</template
          >
          <template v-if="contract.import.show"
            >IMPORT CONTRACT ({{ contract.profile.Name }})</template
          >
          <template v-if="contract.view.show"
            >VIEW CONTRACT ({{ contract.profile.Name }})</template
          >
          <template v-if="portfolio.show">CREATE A NEW PortFolio</template>
        </template>
        <template v-slot:body>
          <!-- contract -->
          <div class="new-record--wrap text-center" v-if="contract.show">
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              v-on:click.prevent="openSideModal('createContract', 'manual')"
              >create a new contract manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              v-on:click.prevent="openSideModal('createContract', 'import')"
              >import data</a
            >
          </div>
          <add-edit-contract
            v-if="contract.manual.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @refreshContracts="getProfile()"
          ></add-edit-contract>
          <import-contract
            v-if="contract.import.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @contractImported="getProfile()"
          ></import-contract>
          <view-contract
            v-if="contract.view.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @refreshContracts="getContracts()"
          ></view-contract>

          <add-portfolio
            v-if="portfolio.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @refreshContracts="getProfile()"
          ></add-portfolio>
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_roster &&
        (userPermissions.profile_roster.length === 0 ||
          (userPermissions.profile_roster.length > 0 &&
            !userPermissions.profile_roster.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<style scoped>
.dataTables_scrollBody > table > thead > tr {
  visibility: collapse;
  height: 0px !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none !important;
}
</style>
<script>
import common from "../mixins/common";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

import profileRosterService from "../services/profileRoster.service";
import BillingChartjs from "../components/ProfileRosterBillingChartjs.vue";
import EarningChartjs from "../components/ProfileRosterEarningChartjs.vue";
import SideModal from "../components/SideModal.vue";
import contractService from "../services/contract.service";
import ImportContract from "../components/ImportContract.vue";
import AddEditContract from "../components/AddEditContract.vue";
import ViewContract from "../components/ViewContract.vue";
import AddPortfolio from "../components/AddPortfolio.vue";
import AccessDenied from "../components/AccessDenied.vue";

export default {
  data() {
    return {
      loading: true,
      uploadBaseUrl: process.env.VUE_APP_UPLOAD_BASE_PATH,
      ProfileId: this.$route.params.ID,
      profile: null,
      progressPrecentage: 0,
      contract: {
        show: false,
        profile: {
          Id: "",
          Name: "",
          contractId: "",
          contractData: {},
          HourlyRate: "",
        },
        manual: {
          show: false,
        },
        import: {
          show: false,
        },
        view: {
          show: false,
        },
      },
      portfolio: {
        show: false,
      },
    };
  },
  mixins: [common],
  components: {
    BillingChartjs,
    EarningChartjs,
    SideModal,
    ImportContract,
    AddEditContract,
    ViewContract,
    AddPortfolio,
    AccessDenied
},
  computed: {
    showPortfolioBtn() {
      let sum = 0;
      var item = this.profile.portfolio;
      for (let x in item) {
        sum += item[x].contractIds.length;
      }
      return sum;
    },
  },
  async mounted() {
    await this.getProfile();
    this.loading = false;
  },
  methods: {
    async getProfile() {
      let vm = this;
      try {
        vm.$store.commit("loadingStatus", true);
        const profile = await profileRosterService.getProfileData(vm.ProfileId);

        vm.profile = profile.data;
        vm.contract.profile.Name = profile.data.ProfileName;
        vm.contract.profile.Id = profile.data.Id;
        vm.contract.profile.HourlyRate =
          profile.data.profile_professional_detail &&
          profile.data.profile_professional_detail.HourlyRate
            ? profile.data.profile_professional_detail.HourlyRate
            : "";

        vm.progressPrecentage = Math.round(
          (100 / 2) *
            (profile.data.education_history_count + profile.data.employment_history_count)
        );

        var act = $("#active_contracts").DataTable();
        act.destroy();

        var dT = $("#mappingHistory").DataTable();
        dT.destroy();

        vm.$nextTick(function () {
          $("#active_contracts").DataTable({
            bInfo: false,
            searching: false,
            ordering: false,
            lengthChange: false,
            fnDrawCallback: function (oSettings) {
              if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
              } else {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
              }
            },
          });
          $("#mappingHistory").DataTable({
            scrollY: "289px",
            scrollCollapse: true,
            bInfo: false,
            ordering: false,
            lengthChange: false,
            searching: false,
            paging: false,
            initComplete: function () {
              $(".dataTables_scrollBody").addClass("custom-scroll");
              $("#mappingHistory_wrapper .dataTables_scrollHeadInner").css({
                "padding-right": "0",
                width: "100%",
              });
              $(
                "#mappingHistory_wrapper .dataTables_scrollHeadInner table.dataTable.no-footer"
              ).css({ width: "100%" });
              $(".dataTables_wrapper.no-footer .dataTables_scrollBody").css({
                "border-bottom": "none",
              });
            },
            fnDrawCallback: function (oSettings) {
              if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
              } else {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
              }
            },
          });
        });
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    async openSideModal(action, method = "", contractId = 0) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      vm.contract.show = false;
      vm.contract.profile.contractId = contractId;
      vm.contract.profile.contractData = {};

      if (
        vm.userPermissions &&
        vm.userPermissions.profile_roster &&
        vm.userPermissions.profile_roster.length > 0 &&
        vm.userPermissions.profile_roster.includes("View")
      ) {
        if (
          action === "createContract" &&
          vm.userPermissions.profile_roster.includes("Add")
        ) {
          if (method === "manual") {
            document.body.classList.remove("lmodal-open");
            setTimeout(() => {
              vm.contract.show = false;
              vm.contract.manual.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else if (method === "import") {
            document.body.classList.remove("lmodal-open");

            setTimeout(() => {
              vm.contract.show = false;
              vm.contract.import.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else {
            vm.contract.show = true;
            document.body.classList.add("lmodal-open");
          }
        } else if (
          action === "editContract" &&
          vm.userPermissions.profile_roster.includes("Update")
        ) {
          vm.$store.commit("loadingStatus", true);

          await contractService
            .getContractData(contractId)
            .then((response) => {
              delete response.data.created_at;
              delete response.data.updated_at;
              vm.contract.profile.contractData = response.data;
              setTimeout(() => {
                vm.contract.show = false;
                vm.contract.manual.show = true;
                document.body.classList.add("lmodal-open");
              }, 300);
            })
            .catch((error) => {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
            })
            .finally(() => {
              vm.$store.commit("loadingStatus", false);
            });
        } else if (
          action === "viewContract" &&
          vm.userPermissions.profile_roster.includes("View")
        ) {
          vm.$store.commit("loadingStatus", true);

          await contractService
            .getContractData(contractId)
            .then((response) => {
              delete response.data.created_at;
              delete response.data.updated_at;
              vm.contract.profile.contractData = response.data;
              setTimeout(() => {
                vm.contract.show = false;
                vm.contract.view.show = true;
                document.body.classList.add("lmodal-open");
              }, 300);
            })
            .catch((error) => {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
            })
            .finally(() => {
              vm.$store.commit("loadingStatus", false);
            });
        } else if (
          action === "addPortfolio" &&
          vm.userPermissions.profile_roster.includes("Add")
        ) {
          vm.portfolio.show = true;
          document.body.classList.add("lmodal-open");
        }
      }
    },
    closeSideModal() {
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.contract.show = false;
        this.contract.manual.show = false;
        this.contract.import.show = false;
        this.contract.view.show = false;
        this.portfolio.show = false;
      }, 300);
    },
  },
};
</script>
